const generateDataFromAPI = (dates, faultCodes, warningCodes, kwUsage) => {
  return [
    {
      name: "Fault Codes",
      data: dates.map((date, index) => ({
        x: date.substring(5), // Format date to MM-DD
        y: faultCodes[index] !== null ? faultCodes[index] : -1, // Using -1 for null values
      })),
    },
    {
      name: "Warning Codes",
      data: dates.map((date, index) => ({
        x: date.substring(5), // Format date to MM-DD
        y: warningCodes[index] !== null ? warningCodes[index] : -1, // Using -1 for null values
      })),
    },
    {
      name: "KW Usage",
      data: dates.map((date, index) => ({
        x: date.substring(5), // Format date to MM-DD
        y: kwUsage[index] !== null ? kwUsage[index] : 0, // Use 0 if kwUsage is null
      })),
    },
  ];
};

export const getHeatmapOptions = (dates) => {
  return {
    chart: {
      height: 450,
      type: "heatmap",
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"],
      },
      formatter: (value) => (value === -1 ? "null" : value.toFixed(2)), // Display 'null' for -1 values
    },
    colors: [
      "#008FFB", // Blue for kW usage
      "#FFB74D", // Yellow for warnings
      "#E57373", // Red for faults
    ],
    xaxis: {
      type: "category",
      categories: dates.map((date) => date.substring(5)), // Format date to MM-DD
    },
    yaxis: {
      categories: ["Fault Codes", "Warning Codes", "KW Usage"],
      title: {
        text: "Metrics",
      },
    },
    grid: {
      padding: {
        right: 20,
      },
    },
    tooltip: {
      y: {
        formatter: (value) => (value === -1 ? "null" : value.toFixed(2)), // Display 'null' for -1 values
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: -1,
              to: -1,
              color: "#FFEBEE", // Light red for null values
              name: "Null",
            },
            {
              from: 0,
              to: 10,
              color: "#E57373", // Red gradient for fault codes
              name: "Fault",
            },
            {
              from: 0,
              to: 10,
              color: "#FFB74D", // Yellow gradient for warning codes
              name: "Warning",
            },
            {
              from: 0,
              to: 100,
              color: "#008FFB", // Blue gradient for kW usage
              name: "KW Usage",
            },
          ],
        },
      },
    },
    legend: {
      show: false, // Hide the legend
    },
  };
};

export { generateDataFromAPI };
