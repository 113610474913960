import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import evcharger from "assets/images/ev-charger.jpg";
import { formatDistanceToNow } from "date-fns";
import "./styles.css";

const minutesAgo = (dateString) => {
  const date = new Date(dateString);
  return formatDistanceToNow(date, { addSuffix: true });
};

const Author = ({ image, name, location, link }) => (
  <Link to={link} className="author-link">
    <MDBox display="flex" alignItems="center" lineHeight={1} className="grid-item">
      <MDAvatar src={image} name={name} size="md" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{location}</MDTypography>
      </MDBox>
    </MDBox>
  </Link>
);

Author.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired, // Add link prop type
};

const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left" className="grid-item">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
    <MDTypography variant="caption">{description}</MDTypography>
  </MDBox>
);

Job.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const getPortStateColor = (state) => {
  switch (state) {
    case "AVL":
      return "success";
    case "CHG":
      return "info";
    case "IDL":
      return "warning";
    case "FLT":
      return "error";
    default:
      return state ? "warning" : "default"; // "default" or "warning" for empty or unknown states
  }
};

export default function Data() {
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("JWT token not found");
          setError("JWT token not found");
          return;
        }

        const response = await axios.get(`${baseURL}/api/evchargers/status?${APIKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
          },
        });
        const data = response.data;

        if (!data || !Array.isArray(data)) {
          setError("Invalid data format received");
          return;
        }

        const formattedData = data.map((charger) => {
          const hostnameParts = charger.Hostname.split("-");
          const gateIndex = hostnameParts.findIndex((part) => part.toLowerCase().includes("gate"));

          if (gateIndex === -1) {
            console.error("Hostname does not contain 'gate':", charger.Hostname);
            return null;
          }

          const name = hostnameParts.slice(0, gateIndex).join("-");
          const location = hostnameParts.slice(gateIndex).join("-");
          const gateNumber = parseInt(location.split("gate")[1], 10);

          const portAState = charger.Ports?.A?.Status?.PortState || "";
          const portBState = charger.Ports?.B?.Status?.PortState || "";

          const portAUpdated = charger.Ports?.A?.Status?.LastUpdated
            ? minutesAgo(charger.Ports.A.Status.LastUpdated)
            : "Data not available";
          const portBUpdated = charger.Ports?.B?.Status?.LastUpdated
            ? minutesAgo(charger.Ports.B.Status.LastUpdated)
            : "Data not available";

          const chargerLink = `/charger/${charger.Hostname}`;

          return {
            gateNumber, // Add gateNumber for sorting
            author: <Author image={evcharger} name={name} location={location} link={chargerLink} />,
            function: <Job title={charger.model} description="Charger" />,
            PortA: (
              <MDBox
                ml={-1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%" // Ensure the box takes the full height
                className="grid-item css-15jcv17"
              >
                <MDBadge
                  badgeContent="Port A"
                  color={getPortStateColor(portAState)}
                  variant="gradient"
                  size="sm"
                  sx={{
                    marginBottom: 1, // Added margin-bottom
                  }}
                />
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  textAlign="center"
                >
                  {portAUpdated}
                </MDTypography>
              </MDBox>
            ),
            PortB: (
              <MDBox
                ml={-1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%" // Ensure the box takes the full height
                className="grid-item css-15jcv17"
              >
                <MDBadge
                  badgeContent="Port B"
                  color={getPortStateColor(portBState)}
                  variant="gradient"
                  size="sm"
                  sx={{
                    marginBottom: 1, // Added margin-bottom
                  }}
                />
                <MDTypography
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  textAlign="center"
                >
                  {portBUpdated}
                </MDTypography>
              </MDBox>
            ),
            action: (
              <Link to={chargerLink}>
                <MDTypography
                  component="span"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  className="responsive-arrow"
                >
                  →
                </MDTypography>
              </Link>
            ),
          };
        });

        // Sort the data by gateNumber in ascending order
        const sortedData = formattedData
          .filter((item) => item !== null)
          .sort((a, b) => a.gateNumber - b.gateNumber);

        setRows(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  const sortRowsByGate = (rows) => {
    return rows.sort((a, b) => {
      const gateA = a.author.props.location.match(/\d+/)[0];
      const gateB = b.author.props.location.match(/\d+/)[0];
      return parseInt(gateA) - parseInt(gateB);
    });
  };

  return {
    columns: [
      { Header: "ID", accessor: "author", width: "45%", align: "left", className: "css-1nos2st" },
      { Header: "function", accessor: "function", align: "left", className: "css-1nos2st" },
      { Header: "Port A", accessor: "PortA", align: "center", className: "css-15jcv17" },
      { Header: "Port B", accessor: "PortB", align: "center", className: "css-15jcv17" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: sortRowsByGate(rows),
  };
}
