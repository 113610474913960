/** */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui/material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Dashboard base styles
import typography from "assets/theme/base/typography";

function Footer({ company = { href: "#", name: "Your Company" }, links = [] }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, made by
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}></MDBox>
        &nbsp;
        <Link href={href} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            Sebastian Kruszewski and Jonathan Battista.
          </MDTypography>
        </Link>
      </MDBox>
      <MDBox
        component="ul"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        m={0}
        p={0}
        listStyle="none"
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  company: { href: "#", name: "Your Company" },
  links: [],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.shape({
    href: PropTypes.string,
    name: PropTypes.string,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default Footer;
