import React, { useState, useEffect } from "react";
import axios from "axios";

// @mui/material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Custom styles
import "./styles.css"; // Import the CSS file

function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const [chargers, setChargers] = useState([]);
  const [selectedCharger, setSelectedCharger] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedVehicleID, setSelectedVehicleID] = useState("all");
  const [selectedAlertType, setSelectedAlertType] = useState("all");
  const [allRecords, setAllRecords] = useState([]);
  const [locations, setLocations] = useState([]);
  const [vehicleIDs, setVehicleIDs] = useState([]);

  const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("JWT token not found");
      return;
    }

    axios
      .get(`${baseURL}/api/evchargers/status?${APIKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
        },
      })
      .then((response) => {
        const data = response.data;
        setChargers(data);

        let records = [];
        let uniqueLocations = new Set();
        let uniqueVehicleIDs = new Set();

        data.forEach((charger) => {
          const hostname = charger.Hostname;
          const location = hostname.split("-").slice(-2).join("-");
          uniqueLocations.add(location);

          const portAFaultRecords = (charger.Ports.A.FaultRecords || []).map((record) => {
            uniqueVehicleIDs.add(record.VehicleID);
            return { ...record, hostname, location, isWarning: record.FaultCode === 0 };
          });
          const portBFaultRecords = (charger.Ports.B.FaultRecords || []).map((record) => {
            uniqueVehicleIDs.add(record.VehicleID);
            return { ...record, hostname, location, isWarning: record.FaultCode === 0 };
          });

          records = records.concat(portAFaultRecords, portBFaultRecords);
        });

        // Sort the records by FaultDateTime
        records.sort((a, b) => new Date(b.FaultDateTime) - new Date(a.FaultDateTime));

        // Remove duplicates
        const uniqueRecords = records.filter(
          (record, index, self) =>
            index ===
            self.findIndex(
              (r) =>
                r.FaultDateTime === record.FaultDateTime &&
                r.hostname === record.hostname &&
                r.VehicleID === record.VehicleID
            )
        );

        setAllRecords(uniqueRecords);
        setLocations([...uniqueLocations]);
        setVehicleIDs([...uniqueVehicleIDs]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [baseURL, APIKey]);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const handleSelectChange = (event) => {
    setSelectedCharger(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleVehicleIDChange = (event) => {
    setSelectedVehicleID(event.target.value);
  };

  const handleAlertTypeChange = (event) => {
    setSelectedAlertType(event.target.value);
  };

  const filteredRecords = allRecords.filter((record) => {
    const matchesCharger = selectedCharger === "all" || record.hostname === selectedCharger;
    const matchesLocation = selectedLocation === "all" || record.location === selectedLocation;
    const matchesDate =
      !selectedDate ||
      new Date(record.FaultDateTime).toDateString() === new Date(selectedDate).toDateString();
    const matchesVehicleID = selectedVehicleID === "all" || record.VehicleID === selectedVehicleID;
    const matchesAlertType =
      selectedAlertType === "all" ||
      (selectedAlertType === "warnings" && record.isWarning) ||
      (selectedAlertType === "faults" && !record.isWarning);
    return matchesCharger && matchesLocation && matchesDate && matchesVehicleID && matchesAlertType;
  });

  const renderRecord = (record) => {
    const alertColor = record.isWarning ? "warning" : "error";

    return (
      <MDAlert
        key={`${record.hostname}-${record.FaultDateTime}-${record.VehicleID}`}
        color={alertColor}
        dismissible
      >
        <MDTypography variant="body2" color="white">
          <strong>{record.hostname.toUpperCase()}</strong>
          <br />
          Fault Code: {record.FaultCode} <br />
          {record.isWarning && (
            <>
              Warning Code: {record.WarningCode} <br />
            </>
          )}
          Message: {record.Message} <br />
          Vehicle ID: {record.VehicleID} <br />
          Port: {record.Port} <br />
          Last Updated: {new Date(record.LastUpdated).toLocaleString()} <br />
          Fault Date Time: {new Date(record.FaultDateTime).toLocaleString()} <br />
        </MDTypography>
      </MDAlert>
    );
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className="filter-container"
              >
                <MDTypography variant="h5">Alerts</MDTypography>
                <Select
                  value={selectedCharger}
                  onChange={handleSelectChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="all">
                    <em className="allchargerpadding">All Chargers</em>
                  </MenuItem>
                  {chargers.map((charger) => (
                    <MenuItem key={charger.Hostname} value={charger.Hostname}>
                      {charger.Hostname}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={selectedLocation}
                  onChange={handleLocationChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ mx: 1 }}
                >
                  <MenuItem value="all">
                    <em>All Locations</em>
                  </MenuItem>
                  {locations.map((location) => (
                    <MenuItem key={location} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mx: 1 }}
                  className="date-picker"
                />
                <Select
                  value={selectedVehicleID}
                  onChange={handleVehicleIDChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ mx: 1 }}
                >
                  <MenuItem value="all">
                    <em>All Vehicle IDs</em>
                  </MenuItem>
                  {vehicleIDs.map((vehicleID) => (
                    <MenuItem key={vehicleID} value={vehicleID}>
                      {vehicleID}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  value={selectedAlertType}
                  onChange={handleAlertTypeChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ mx: 1 }}
                >
                  <MenuItem value="all">
                    <em>All Alerts</em>
                  </MenuItem>
                  <MenuItem value="warnings">Warnings</MenuItem>
                  <MenuItem value="faults">Faults</MenuItem>
                </Select>
              </MDBox>
              <MDBox pt={2} px={2} className="alert-section">
                {filteredRecords.map((record) => renderRecord(record))}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {renderSuccessSB}
      {renderInfoSB}
      {renderWarningSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Notifications;
