import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { generateDataFromAPI, getHeatmapOptions } from "./heatmapData";
import "./styles.css";

// Function to get the last 14 days dates
const getPast14Days = () => {
  const today = new Date();
  return Array.from({ length: 14 }, (_, i) => {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    return date.toISOString().split("T")[0];
  }).reverse();
};

const HeatMapChart = ({ title, hostname }) => {
  const [chartState, setChartState] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("JWT token not found");
          return;
        }

        const response = await axios.get(`${baseURL}/api/evchargers/status?${APIKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
          },
        });

        const data = response.data;
        const dates = getPast14Days();

        const charger = data.find((c) => c.Hostname === hostname);

        if (!charger) {
          console.error("Charger not found");
          return;
        }

        const kwUsage = dates.map((date) => {
          const connectionRecordsA = charger.Ports?.A?.ConnectionRecords || [];
          const connectionRecordsB = charger.Ports?.B?.ConnectionRecords || [];
          return connectionRecordsA
            .concat(connectionRecordsB)
            .filter((record) => record.LastUpdated.startsWith(date))
            .reduce((total, record) => total + (record.KWHConsumed || 0), 0);
        });

        const faultCodes = dates.map((date) => {
          const faultRecordsA = charger.Ports?.A?.FaultRecords || [];
          const faultRecordsB = charger.Ports?.B?.FaultRecords || [];
          return faultRecordsA
            .concat(faultRecordsB)
            .filter((record) => record.LastUpdated.startsWith(date) && record.FaultCode !== 0)
            .length;
        });

        const warningCodes = dates.map((date) => {
          const warningRecordsA = charger.Ports?.A?.FaultRecords || [];
          const warningRecordsB = charger.Ports?.B?.FaultRecords || [];
          return warningRecordsA
            .concat(warningRecordsB)
            .filter((record) => record.LastUpdated.startsWith(date) && record.WarningCode !== 0)
            .length;
        });

        const series = generateDataFromAPI(dates, faultCodes, warningCodes, kwUsage);

        const options = getHeatmapOptions(dates);

        setChartState({ series, options });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [hostname]);

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex" flexDirection="column" alignItems="center" p={2}>
        <MDTypography variant="h6" gutterBottom>
          {title}
        </MDTypography>
        <div id="chart-container">
          <div id="chart" style={{ width: "100%" }}>
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="heatmap"
              height={450}
              width="100%"
            />
          </div>
        </div>
      </MDBox>
    </Card>
  );
};

HeatMapChart.propTypes = {
  title: PropTypes.string.isRequired,
  hostname: PropTypes.string.isRequired,
};

export default HeatMapChart;
