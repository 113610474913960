import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// @mui/material components
import Grid from "@mui/material/Grid";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Custom styles
import "./dashboard.css";

// Import the HeatMapChart components
import DesktopHeatMapChart from "./components/HeatMap/index";
import MobileHeatMapChart from "./components/HeatMapMobile/index";

// Custom hook for viewport width detection
const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { width };
};

function Dashboard() {
  const { width } = useViewport();
  const breakpoint = 1652; // Change the breakpoint to 1256px

  const navigate = useNavigate();

  // State to hold the charger data
  const [totalChargers, setTotalChargers] = useState(0);
  const [onlineChargers, setOnlineChargers] = useState(0);
  const [offlineChargers, setOfflineChargers] = useState(0);
  const [chargingPorts, setChargingPorts] = useState(0);
  const [idlePorts, setIdlePorts] = useState(0);
  const [availablePorts, setAvailablePorts] = useState(0);
  const [faultedPorts, setFaultedPorts] = useState(0);
  const [totalKilowatts, setTotalKilowatts] = useState(0);
  const [totalVolts, setTotalVolts] = useState(0);
  const [totalKilowatts24Hrs, setTotalKilowatts24Hrs] = useState(0);

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;

  useEffect(() => {
    const validateToken = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("JWT token not found");
        navigate("/authentication/sign-in");
        return false;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          navigate("/authentication/sign-in");
          return false;
        }
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
        navigate("/authentication/sign-in");
        return false;
      }

      return true;
    };

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("JWT token not found");
          navigate("/authentication/sign-in");
          return;
        }

        const response = await axios.get(`${baseURL}/api/evchargers/status?${APIKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
          },
        });

        const data = response.data;
        let totalChargersCount = 0;
        let onlineCount = 0;
        let offlineCount = 0;
        let chargingCount = 0;
        let idleCount = 0;
        let availableCount = 0;
        let faultedCount = 0;
        let totalVoltage = 0;
        let totalKilowatts24HrsCount = 0;

        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);

        data.forEach((charger) => {
          totalChargersCount += 1;

          const ports = [charger.Ports.A, charger.Ports.B];
          let isOnline = false;
          let isOffline = false;

          ports.forEach((port) => {
            const portState = port?.Status?.PortState || "";
            const portVoltage = port?.Status?.PortVoltage || 0;

            if (portState === "AVL" || portState === "IDL" || portState === "CHG") {
              isOnline = true;
            } else {
              isOffline = true;
            }

            if (portState === "CHG") {
              chargingCount += 1;
            } else if (portState === "IDL") {
              idleCount += 1;
            } else if (portState === "AVL") {
              availableCount += 1;
            } else {
              faultedCount += 1;
            }

            totalVoltage += portVoltage;
            if (port?.ChargeRecords) {
              port.ChargeRecords.forEach((record) => {
                const recordEndTime = new Date(record.EndDateTime);
                if (recordEndTime >= yesterday) {
                  const kWUsage = record.TotalKWHCharged;
                  totalKilowatts24HrsCount += kWUsage;
                }
              });
            }
          });

          if (isOnline) {
            onlineCount += 1;
          } else if (isOffline) {
            offlineCount += 1;
          }
        });

        setTotalChargers(totalChargersCount);
        setOnlineChargers(onlineCount);
        setOfflineChargers(totalChargersCount - onlineCount);
        setChargingPorts(chargingCount);
        setIdlePorts(idleCount);
        setAvailablePorts(availableCount);
        setFaultedPorts(faultedCount);
        setTotalKilowatts(totalVoltage / 1000); // Convert volts to kilowatts per hour
        setTotalVolts(totalVoltage);
        setTotalKilowatts24Hrs(totalKilowatts24HrsCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (validateToken()) {
      fetchData();
    }
  }, [baseURL, APIKey, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={5} pb={6} className="dashboard-container-1">
        <Grid container spacing={3} pb={5} className="tChargers-section-container">
          <Grid item xs={12} className="total-chargers-container">
            <MDBox display="flex" justifyContent="center" mb={3}>
              <MDTypography variant="h2" fontWeight="bold" className="dashboard-header-text">
                Total Chargers
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} className="total-chargers-box">
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="ev_station"
                title="Total Chargers"
                count={totalChargers}
                percentage={{
                  color: "info",
                  amount: "",
                  label: "Total number of chargers",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="power"
                title="Online Chargers"
                count={onlineChargers}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Currently online",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="power_off"
                title="Offline Chargers"
                count={offlineChargers}
                percentage={{
                  color: "error",
                  amount: "",
                  label: "Currently offline",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} pb={5} className="pSummary-section-container">
          <Grid item xs={12} className="total-chargers-container">
            <MDBox display="flex" justifyContent="center" mb={3}>
              <MDTypography variant="h2" fontWeight="bold" className="dashboard-header-text">
                Port Summary
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} className="total-chargers-box">
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="ev_station"
                title="Charging"
                count={chargingPorts}
                percentage={{
                  color: "info",
                  amount: "",
                  label: "Currently Active",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="power"
                title="Idle"
                count={idlePorts}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Currently Idle",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="power_off"
                title="Available"
                count={availablePorts}
                percentage={{
                  color: "error",
                  amount: "",
                  label: "Currently Available",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="power_off"
                title="Faulted/NA"
                count={faultedPorts}
                percentage={{
                  color: "error",
                  amount: "",
                  label: "Currently Faulted/NA",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={5} pb={6} className="dashboard-container-2">
        <Grid container spacing={3} pb={5} className="pOutput-section-container">
          <Grid item xs={12} className="total-chargers-container">
            <MDBox display="flex" justifyContent="center" mb={3}>
              <MDTypography variant="h2" fontWeight="bold" className="dashboard-header-text">
                Power Output
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="bolt"
                title="Killowatt output (Last 24hr)"
                count={totalKilowatts24Hrs.toFixed(2)}
                percentage={{
                  color: "error",
                  amount: "",
                  label: "Total Killowatt output over the last 24 hours",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="bolt"
                title="Current Volts"
                count={totalVolts}
                percentage={{
                  color: "error",
                  amount: "",
                  label: "Total Volts currently output",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={5} pb={6} className="dashboard-container-3">
        <Grid container spacing={3} pb={5} className="heatmap-section-container">
          <Grid item xs={12} className="total-chargers-container">
            <MDBox display="flex" justifyContent="center" mb={3}>
              <MDTypography variant="h2" fontWeight="bold" className="dashboard-header-text">
                HeatMap
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox mb={1.5}>
              {width < breakpoint ? (
                <MobileHeatMapChart title="Last 7 days" />
              ) : (
                <DesktopHeatMapChart title="Last 7 days" />
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
