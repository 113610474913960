const generateDataFromAPI = (chargers, faultCodes, warningCodes, kwUsage) => {
  return [
    {
      name: "Fault Codes",
      data: chargers.map((charger, index) => ({
        x: charger,
        y: faultCodes[index] !== null ? faultCodes[index] : -1, // Using -1 for null values
      })),
    },
    {
      name: "Warning Codes",
      data: chargers.map((charger, index) => ({
        x: charger,
        y: warningCodes[index] !== null ? warningCodes[index] : -1, // Using -1 for null values
      })),
    },
    {
      name: "KW Usage",
      data: chargers.map((charger, index) => ({
        x: charger,
        y: kwUsage[index] !== null ? kwUsage[index] : 0, // Use 0 if kwUsage is null
      })),
    },
  ];
};

export const getHeatmapOptions = (chargers) => {
  return {
    chart: {
      height: 450,
      type: "heatmap",
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"],
      },
      formatter: (value) => (value === -1 ? "null" : value.toFixed(2)), // Display 'null' for -1 values
    },
    colors: ["#008FFB", "#FF0000"], // Blue for values, red for null values
    xaxis: {
      type: "category",
      categories: chargers,
    },
    yaxis: {
      categories: ["Fault Codes", "Warning Codes", "KW Usage"],
      title: {
        text: "Metrics",
      },
    },
    grid: {
      padding: {
        right: 20,
      },
    },
    tooltip: {
      y: {
        formatter: (value) => (value === -1 ? "null" : value.toFixed(2)), // Display 'null' for -1 values
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: -1,
              to: -1,
              color: "#FF0000",
              name: "Null",
            },
            {
              from: 0,
              to: 100,
              color: "#008FFB",
              name: "Value",
            },
          ],
        },
      },
    },
    legend: {
      show: false, // Hide the legend
    },
  };
};

export { generateDataFromAPI };
