import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { generateDataFromAPI, getHeatmapOptions } from "./mobilemapData";
import "./styles.css"; // Import the CSS file

const getFaultCodesLastWeek = (faultRecords) => {
  if (faultRecords === null) {
    return null; // Return null if faultRecords is null
  }

  if (!faultRecords || faultRecords.length === 0) {
    return 0; // Return 0 if there are no records
  }

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return faultRecords.filter((record) => {
    const recordDate = new Date(record.LastUpdated);
    return recordDate >= oneWeekAgo && record.FaultCode !== 0;
  }).length;
};

const getWarningCodesLastWeek = (faultRecords) => {
  if (faultRecords === null) {
    return null; // Return null if faultRecords is null
  }

  if (!faultRecords || faultRecords.length === 0) {
    return 0; // Return 0 if there are no records
  }

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return faultRecords.filter((record) => {
    const recordDate = new Date(record.LastUpdated);
    return recordDate >= oneWeekAgo && record.WarningCode !== 0;
  }).length;
};

const getKWUsageLastWeek = (connectionRecords) => {
  if (!connectionRecords || connectionRecords.length === 0) {
    return 0; // Return 0 if there are no records
  }

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return connectionRecords
    .filter((record) => new Date(record.LastUpdated) >= oneWeekAgo)
    .reduce((total, record) => total + (record.KWHConsumed || 0), 0);
};

const HeatMapChart = ({ title }) => {
  const [chartState, setChartState] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("JWT token not found");
          return;
        }

        const response = await axios.get(`${baseURL}/api/evchargers/status?${APIKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
          },
        });

        const data = response.data;

        const chargers = data.map((charger) => charger.Hostname);
        const faultCodes = data.map(
          (charger) =>
            getFaultCodesLastWeek(charger.Ports.A.FaultRecords) +
            getFaultCodesLastWeek(charger.Ports.B.FaultRecords)
        );
        const warningCodes = data.map(
          (charger) =>
            getWarningCodesLastWeek(charger.Ports.A.FaultRecords) +
            getWarningCodesLastWeek(charger.Ports.B.FaultRecords)
        );
        const kwUsage = data.map(
          (charger) =>
            getKWUsageLastWeek(charger.Ports.A.ConnectionRecords) +
            getKWUsageLastWeek(charger.Ports.B.ConnectionRecords)
        );

        const series = generateDataFromAPI(chargers, faultCodes, warningCodes, kwUsage);
        const options = getHeatmapOptions(chargers);

        setChartState({ series, options });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex" flexDirection="column" alignItems="center" p={2}>
        <MDTypography variant="h6" gutterBottom>
          {title}
        </MDTypography>
        <div id="chart-container">
          <div id="chart" style={{ width: "100%" }}>
            <ReactApexChart
              options={chartState.options}
              series={chartState.series}
              type="heatmap"
              height={450}
              width={Math.max(1200, 100 * chartState.series[0]?.data.length)} // Ensure width is enough for scrolling
            />
          </div>
        </div>
      </MDBox>
    </Card>
  );
};

HeatMapChart.propTypes = {
  title: PropTypes.string.isRequired,
};

export default HeatMapChart;
