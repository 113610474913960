import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// @mui/material components
import Grid from "@mui/material/Grid";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// HeatMap components
import DesktopHeatMapChart from "./components/HeatMap/index";
import MobileHeatMapChart from "./components/HeatMapMobile/index";

// MUI icons
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import EvStationIcon from "@mui/icons-material/EvStation";
import DangerousIcon from "@mui/icons-material/Dangerous";
import ReportIcon from "@mui/icons-material/Report";
import BoltIcon from "@mui/icons-material/Bolt";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import HistoryIcon from "@mui/icons-material/History";
import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const getFaultCodesLastWeek = (faultRecords) => {
  if (!faultRecords || faultRecords.length === 0) {
    return 0; // Return 0 if there are no records
  }

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return faultRecords.filter((record) => {
    const recordDate = new Date(record.LastUpdated);
    return recordDate >= oneWeekAgo && record.FaultCode !== 0;
  }).length;
};

const getBatteryTemperatureDetails = (temperature) => {
  if (temperature === 0) {
    return {
      color: "primary",
      amount: "",
      label: "No battery charging",
    };
  } else if (temperature >= 21 && temperature <= 49) {
    return {
      color: "success",
      amount: "Good",
      label: "Battery is at a stable temperature",
    };
  } else if (temperature >= 50 && temperature < 75) {
    return {
      color: "warning",
      amount: "Warning",
      label: "Battery is warm",
    };
  } else if (temperature <= 20) {
    return {
      color: "warning",
      amount: "Warning",
      label: "Battery is cold",
    };
  } else if (temperature >= 75) {
    return {
      color: "error",
      amount: "Danger",
      label: "Battery is overheating",
    };
  } else {
    return {
      color: "primary",
      amount: "Unknown",
      label: "Temperature out of range",
    };
  }
};

const getWarningCodesLastWeek = (faultRecords) => {
  if (!faultRecords || faultRecords.length === 0) {
    return 0; // Return 0 if there are no records
  }

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return faultRecords.filter((record) => {
    const recordDate = new Date(record.LastUpdated);
    return recordDate >= oneWeekAgo && record.WarningCode !== 0;
  }).length;
};

const processConnectionRecords = (connectionRecords) => {
  if (!connectionRecords || connectionRecords.length === 0) {
    return {
      totalKWHConsumed: 0,
      totalAHConsumed: 0,
      earliestDate: "No records",
      totalTimeConnected: 0,
      mostRecentVehicleID: "No records",
    };
  }

  let totalKWHConsumed = 0;
  let totalAHConsumed = 0;
  let totalTimeConnected = 0; // Time connected in seconds
  let earliestDate = new Date(connectionRecords[0].LastUpdated);
  let mostRecentVehicleID = connectionRecords[0].VehicleID;

  connectionRecords.forEach((record) => {
    const recordDate = new Date(record.LastUpdated);
    if (recordDate < earliestDate) {
      earliestDate = recordDate;
    }
    if (recordDate > new Date(mostRecentVehicleID.LastUpdated)) {
      mostRecentVehicleID = record.VehicleID;
    }
    totalKWHConsumed += record.KWHConsumed || 0; // Ensure a default value of 0 if undefined
    totalAHConsumed += record.AHConsumed || 0; // Ensure a default value of 0 if undefined
    totalTimeConnected += record.TimeConnected || 0; // Ensure a default value of 0 if undefined
  });

  totalKWHConsumed = Math.round(totalKWHConsumed * 10) / 10; // Round to the first decimal place
  totalAHConsumed = Math.round(totalAHConsumed * 10) / 10; // Round to the first decimal place
  const totalTimeConnectedMinutes = Math.round((totalTimeConnected / 60) * 10) / 10; // Convert to minutes and round

  return {
    totalKWHConsumed,
    totalAHConsumed,
    totalTimeConnectedMinutes,
    earliestDate: earliestDate.toLocaleString(),
    mostRecentVehicleID,
  };
};

const calculateMinutesSinceLastUpdate = (lastUpdated) => {
  if (!lastUpdated) {
    return "Loading...";
  }

  const lastUpdatedDate = new Date(lastUpdated).toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
  const timeDifference = (new Date(currentDate) - new Date(lastUpdatedDate)) / 1000 / 60; // Convert milliseconds to minutes
  const timeDifferenceRounded = Math.round(timeDifference * 10) / 10; // Round to the first decimal place

  return timeDifferenceRounded;
};

const ChargerDetails = () => {
  const { hostname } = useParams();
  const navigate = useNavigate();
  const [chargers, setChargers] = useState([]);
  const [portAData, setPortAData] = useState(null);
  const [portBData, setPortBData] = useState(null);
  const [faultCodesLastWeekA, setFaultCodesLastWeekA] = useState("Loading...");
  const [faultCodesLastWeekB, setFaultCodesLastWeekB] = useState("Loading...");
  const [warningCodesLastWeekA, setWarningCodesLastWeekA] = useState("Loading...");
  const [warningCodesLastWeekB, setWarningCodesLastWeekB] = useState("Loading...");
  const [portAConnectionData, setPortAConnectionData] = useState("Loading...");
  const [portBConnectionData, setPortBConnectionData] = useState("Loading...");
  const batteryTemperatureDetails = portBData
    ? getBatteryTemperatureDetails(portBData.BatteryTemperature)
    : { color: "primary", amount: "Loading...", label: "Loading..." };

  const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
  useEffect(() => {
    const validateToken = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("JWT token not found");
        navigate("/authentication/sign-in");
        return false;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          navigate("/authentication/sign-in");
          return false;
        }
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
        navigate("/authentication/sign-in");
        return false;
      }

      return true;
    };

    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("JWT token not found");
          navigate("/authentication/sign-in");
          return;
        }
        const response = await axios.get(`${baseURL}/api/evchargers/status?${APIKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY,
          },
        });
        const data = response.data;
        setChargers(data);

        if (location.pathname === "/charger/:hostname" && data.length > 0) {
          navigate(`/charger/${data[0].Hostname}`, { replace: true });
        } else if (hostname) {
          const charger = data.find((item) => item.Hostname === hostname);
          if (charger) {
            setPortAData(charger.Ports.A.Status);
            setPortBData(charger.Ports.B.Status);
            const faultCodesA = getFaultCodesLastWeek(charger.Ports.A.FaultRecords);
            const faultCodesB = getFaultCodesLastWeek(charger.Ports.B.FaultRecords);
            setFaultCodesLastWeekA(faultCodesA);
            setFaultCodesLastWeekB(faultCodesB);
            const warningCodesA = getWarningCodesLastWeek(charger.Ports.A.FaultRecords);
            const warningCodesB = getWarningCodesLastWeek(charger.Ports.B.FaultRecords);
            setWarningCodesLastWeekA(warningCodesA);
            setWarningCodesLastWeekB(warningCodesB);
            const portAConnectionData = processConnectionRecords(charger.Ports.A.ConnectionRecords);
            const portBConnectionData = processConnectionRecords(charger.Ports.B.ConnectionRecords);
            setPortAConnectionData(portAConnectionData);
            setPortBConnectionData(portBConnectionData);
          } else {
            console.error("Charger with hostname not found");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (validateToken()) {
      fetchData();
    }
  }, [hostname, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ComplexStatisticsCard color="dark" icon={<EvStationIcon />} title="Port" count="A" />
      <MDBox py={5} pb={6}>
        <Grid container spacing={3} pb={5}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon={<DangerousIcon />}
                title="Current Fault Code"
                count={portAData ? portAData.FaultCode : "Loading..."}
                percentage={{
                  color: "error",
                  amount: faultCodesLastWeekA,
                  label: faultCodesLastWeekA ? "in the last week" : "No results in last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon={<ReportIcon />}
                title="Current Warning Code"
                count={portAData ? portAData.WarningCode : "Loading..."}
                percentage={{
                  color: "warning",
                  amount: warningCodesLastWeekA,
                  label: warningCodesLastWeekA ? "in the last week" : "No results in last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<BoltIcon />}
                title="Port Voltage"
                count={portAData ? portAData.PortVoltage : "Loading..."}
                percentage={{
                  color: "success",
                  amount: portAConnectionData.totalKWHConsumed,
                  label: `KW Hours Consumed since ${portAConnectionData.earliestDate}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<BoltIcon />}
                title="Port Current"
                count={portAData ? portAData.PortCurrent : "Loading..."}
                percentage={{
                  color: "success",
                  amount: portAConnectionData.totalAHConsumed,
                  label: `Amp Hours Consumed since ${portAConnectionData.earliestDate}`,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<HistoryIcon />}
                title="Last Updated"
                count={
                  portAData
                    ? new Date(portAData.LastUpdated).toLocaleString("en-US", {
                        timeZone: "America/Los_Angeles",
                      })
                    : "Loading..."
                }
                percentage={{
                  color: "success",
                  amount: calculateMinutesSinceLastUpdate(portAData?.LastUpdated),
                  label: "minutes ago",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon={<BatteryUnknownIcon />}
                title="State of Charge"
                count={`${portAData ? portAData.StateOfCharge : "Loading..."}%`}
                percentage={{
                  color: "success",
                  amount: portAConnectionData.totalTimeConnectedMinutes,
                  label: `total minutes of charging since ${portAConnectionData.earliestDate}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<BatteryChargingFullIcon />}
                title="Battery Voltage"
                count={portAData ? portAData.BatteryVoltage : "Loading..."}
                percentage={{
                  color: "success",
                  amount: portAConnectionData.mostRecentVehicleID,
                  label: "VehicleID of most recent record",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color={batteryTemperatureDetails.color}
                icon={<BatteryAlertIcon />}
                title="Battery Temperature"
                count={portBData ? portBData.BatteryTemperature : "Loading..."}
                percentage={{
                  color: batteryTemperatureDetails.color,
                  amount: batteryTemperatureDetails.amount,
                  label: batteryTemperatureDetails.label,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <ComplexStatisticsCard color="dark" icon={<EvStationIcon />} title="Port" count="B" />
      <MDBox py={5} pb={6}>
        <Grid container spacing={3} pb={5}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon={<DangerousIcon />}
                title="Current Fault Code"
                count={portBData ? portBData.FaultCode : "Loading..."}
                percentage={{
                  color: "error",
                  amount: faultCodesLastWeekB,
                  label: faultCodesLastWeekB ? "in the last week" : "No results in last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon={<ReportIcon />}
                title="Current Warning Code"
                count={portBData ? portBData.WarningCode : "Loading..."}
                percentage={{
                  color: "warning",
                  amount: warningCodesLastWeekB,
                  label: warningCodesLastWeekB ? "in the last week" : "No results in last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<BoltIcon />}
                title="Port Voltage"
                count={portBData ? portBData.PortVoltage : "Loading..."}
                percentage={{
                  color: "success",
                  amount: portBConnectionData.totalKWHConsumed,
                  label: `KW Hours Consumed since ${portBConnectionData.earliestDate}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<BoltIcon />}
                title="Port Current"
                count={portBData ? portBData.PortCurrent : "Loading..."}
                percentage={{
                  color: "success",
                  amount: portBConnectionData.totalAHConsumed,
                  label: `Amp Hours Consumed since ${portBConnectionData.earliestDate}`,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<HistoryIcon />}
                title="Last Updated"
                count={
                  portAData
                    ? new Date(portAData.LastUpdated).toLocaleString("en-US", {
                        timeZone: "America/Los_Angeles",
                      })
                    : "Loading..."
                }
                percentage={{
                  color: "success",
                  amount: calculateMinutesSinceLastUpdate(portBData?.LastUpdated),
                  label: "minutes ago",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon={<BatteryUnknownIcon />}
                title="State of Charge"
                count={`${portBData ? portBData.StateOfCharge : "Loading..."}%`}
                percentage={{
                  color: "success",
                  amount: portBConnectionData.totalTimeConnectedMinutes,
                  label: `total minutes of charging since ${portBConnectionData.earliestDate}`,
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<BatteryChargingFullIcon />}
                title="Battery Voltage"
                count={portBData ? portBData.BatteryVoltage : "Loading..."}
                percentage={{
                  color: "success",
                  amount: portBConnectionData.mostRecentVehicleID,
                  label: "VehicleID of most recent record",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color={batteryTemperatureDetails.color}
                icon={<BatteryAlertIcon />}
                title="Battery Temperature"
                count={portBData ? portBData.BatteryTemperature : "Loading..."}
                percentage={{
                  color: batteryTemperatureDetails.color,
                  amount: batteryTemperatureDetails.amount,
                  label: batteryTemperatureDetails.label,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={5} pb={6}>
        <Grid container spacing={3} pb={5}>
          <Grid item xs={12}>
            <MDBox mb={1.5}>
              <DesktopHeatMapChart title={hostname} hostname={hostname} />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ChargerDetails;
