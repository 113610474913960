import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import "./styles.css"; // Import the CSS file

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedOption, setSelectedOption] = useState("select");
  const [chargers, setChargers] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const route = location.pathname.split("/").slice(1);

  const APIKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;

  const theme = useTheme(); // Access the theme

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    async function fetchChargers() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("JWT token not found");
          return;
        }

        const response = await axios.get(`${apiBaseUrl}/api/evchargers/status?${APIKey}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        setChargers(data);
      } catch (error) {
        console.error("Error fetching chargers data:", error);
      }
    }
    fetchChargers();
  }, [apiBaseUrl]);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const hostnameFromUrl = pathParts[pathParts.length - 1] || "";

    if (chargers.length > 0 && hostnameFromUrl) {
      setSelectedOption(hostnameFromUrl);
    } else {
      setSelectedOption("select");
    }
  }, [location, chargers]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue && selectedValue !== "select") {
      navigate(`/charger/${selectedValue}`);
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs
            icon={
              <RouterLink to="/chargers" style={{ color: "inherit" }}>
                <Icon>home</Icon>
              </RouterLink>
            }
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })} className="navbar-links">
            <MDBox pr={1}>
              <Select
                value="select"
                onChange={handleSelectChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="select" disabled>
                  <em>Select Charger</em>
                </MenuItem>
                {chargers.map((charger) => (
                  <MenuItem key={charger.Hostname} value={charger.Hostname}>
                    {charger.Hostname}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox display="flex" alignItems="center" className="nav-links">
              <RouterLink to="/chargers" style={{ textDecoration: "none", marginRight: "16px" }}>
                <MDBox
                  component="span"
                  display="flex"
                  alignItems="center"
                  sx={(theme) => ({
                    color: light || darkMode ? theme.palette.white.main : theme.palette.dark.main,
                  })}
                >
                  <Icon>bolt</Icon>
                  <MDBox
                    ml={1}
                    sx={(theme) => ({
                      color: light || darkMode ? theme.palette.white.main : theme.palette.dark.main,
                    })}
                  >
                    Chargers
                  </MDBox>
                </MDBox>
              </RouterLink>
              <RouterLink
                to="/notifications"
                style={{ textDecoration: "none", marginRight: "16px" }}
              >
                <MDBox
                  component="span"
                  display="flex"
                  alignItems="center"
                  sx={(theme) => ({
                    color: light || darkMode ? theme.palette.white.main : theme.palette.dark.main,
                  })}
                >
                  <Icon>notifications</Icon>
                  <MDBox
                    ml={1}
                    sx={(theme) => ({
                      color: light || darkMode ? theme.palette.white.main : theme.palette.dark.main,
                    })}
                  >
                    Notifications
                  </MDBox>
                </MDBox>
              </RouterLink>
              <RouterLink
                to="/summary"
                style={{ textDecoration: "none", marginRight: "16px", display: "none" }}
                className="summary-link"
              >
                <MDBox
                  component="span"
                  display="flex"
                  alignItems="center"
                  sx={(theme) => ({
                    color: light || darkMode ? theme.palette.white.main : theme.palette.dark.main,
                  })}
                >
                  <Icon>dashboard</Icon>
                  <MDBox
                    ml={1}
                    sx={(theme) => ({
                      color: light || darkMode ? theme.palette.white.main : theme.palette.dark.main,
                    })}
                  >
                    Summary
                  </MDBox>
                </MDBox>
              </RouterLink>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
